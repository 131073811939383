<template>
  <div class="cell-wrapper">
    <div class="values-column">
      <span class="forecast-data__container">{{ colValue.forecastAvg }}</span>
      <span class="actual-data__container">{{ colValue.actualAvg }}</span>
    </div>
    <div
      v-if="showComparison"
      class="percent-column"
    >
      <span :class="['comparison-value', percentageClass]">
        {{ comparison >= 0 ? '+' : '' }}{{ comparison }}%
      </span>
    </div>
  </div>
</template>
<script>

import { averageComparisonBands } from '@/components/LaborPlanning/constants';

export default {
  computed: {
    colValue() {
      const { field } = this.params.colDef;
      const rowData = this.params.data;
      return rowData[field];
    },
    textAlignClass() {
      return this?.params?.textAlign || 'right';
    },
    showComparison() {
      const a = this.colValue.actualAvg;
      const f = this.colValue.forecastAvg;
      return !!(a > 0 && f > 0);
    },
    comparison() {
      if (!this.showComparison) return null;
      const a = this.colValue.actualAvg;
      const f = this.colValue.forecastAvg;
      return Math.round(((a - f) * 100) / f);
    },
    percentageClass() {
      const percent = this.comparison;
      return this.mapPercentageToClass(percent);
    }
  },
  methods: {
    mapPercentageToClass(percent) {
      if (percent === null || percent === undefined) return '';

      const found = averageComparisonBands.find(({ range }) => percent >= range[0] && percent < range[1]);
      return found?.class || '';
    }
  }
};
</script>
<style scoped>
.cell-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.values-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60px; /* adjust as needed */
}

.percent-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
}

.forecast-data__container {
  font-size: 16px !important;
  line-height: 18px !important;
}
.actual-data__container {
  font-size: 14px !important;
  color: #918b8b;
  line-height: 15px !important;
}
.comparison-value {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
}

.neutral {
  color: #a0a0a0;
}
.over {
  color: orange;
}
.over-high {
  color: red;
}
.under {
  color: lightgreen;
}
.under-low {
  color: green;
}

.comparison-value {
  font-size: 12px;
  line-height: 14px;
}
</style>

