<template>
  <LaborPlanningSection
    v-bind="$attrs"
    :rowHeight="rowHeight"
    :gridContext="getGridContext"
    @cell-edit-request="onCellEditRequest"
    @gridReady="attachHeaderButtons"
    @firstDataRendered="attachHeaderButtons"
  >
    <template #title>
      <slot name="title" />
    </template>
  </LaborPlanningSection>
</template>

<script>
import LaborPlanningSection from './LaborPlanningSection.vue';

export default {
  components: {
    LaborPlanningSection
  },
  inheritAttrs: false,
  props: [
    'rowHeight',
    'onHeaderAction',
    'attachBtnToHeaders',
    'headerButtonClass',
    'headerButtonIcon',
    'headerButtonTooltip' ,
    'headerButtonStyle',
    'onRevenueInputChange',
    'forecastPremiumDiscountPrefill',
    'inputsReadOnly'
  ],
  emits: ['cellEdited'],
  computed: {
    getGridContext() {
      return {
        onRevenueInputChange: this.onRevenueInputChange,
        prefillInputs: this.forecastPremiumDiscountPrefill,
        inputsReadOnly: this.inputsReadOnly
      };
    }
  },
  watch: {
    attachBtnToHeaders: {
      handler() {
        this.attachHeaderButtons();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.attachHeaderButtons();
  },
  methods: {
    attachHeaderButtons() {
      if (!this.attachBtnToHeaders) return;
      this.$nextTick(() => {
        const table = this.$el.querySelector('.ag-theme-alpine');
        if (!table) return;

        const headers = table.querySelectorAll('.ag-header-cell');

        const processedHeaders = new Set();

        headers.forEach((header) => {
          const columnName = header.textContent.trim();
          if (!this.attachBtnToHeaders.includes(columnName)) {
            return;
          }

          const existingContainer = header.querySelector('.header-button-container');
          if (existingContainer) {
            existingContainer.remove();
          }

          if (!processedHeaders.has(columnName)) {
            processedHeaders.add(columnName);

            const container = document.createElement('div');
            container.className = 'header-button-container';
            container.style.display = 'inline-flex';
            container.style.alignItems = 'center';

            const button = document.createElement('button');
            button.className = this.headerButtonClass;
            Object.assign(button.style, this.headerButtonStyle);
            button.innerHTML = `<i class="${this.headerButtonIcon}"></i>`;
            button.setAttribute('title', this.headerButtonTooltip(columnName));

            button.addEventListener('click', (event) => {
              event.stopPropagation();
              this.onHeaderButtonClick(event, columnName);
            });

            container.appendChild(button);
            header.appendChild(container);
          }
        });
      });
    },
    onHeaderButtonClick(event, columnName) {
      event.stopPropagation();
      if (typeof this.onHeaderAction === 'function') {
        this.onHeaderAction(columnName);
      } else {
        console.warn('onHeaderAction is not a function');
      }
    },
    onCellEditRequest(event) {
      this.$emit('cellEdited', event);
    }
  }
};
</script>

<style scoped>
.header-button {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 14px;
}

:deep(.ag-theme-alpine .ag-header-cell) {
  padding: 2px !important;
  font-size: 14px !important;
  min-width: auto !important;
}

:deep(.ag-theme-alpine .ag-header-cell-label) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px !important;
}

</style>
