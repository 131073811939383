<template>
  <LaborPlanningSection
    v-bind="$attrs"
    @cell-edit-request="onCellEditRequest"
    @grid-ready="attachHeaderInputs"
    @first-data-rendered="attachHeaderInputs"
  >
    <template #title>
      <slot name="title" />
    </template>
  </LaborPlanningSection>
</template>

<script>
import LaborPlanningSection from './LaborPlanningSection.vue';

export default {
  components: {
    LaborPlanningSection
  },
  inheritAttrs: false,
  props: [ 'onInputChange', 'attachInputToHeaders', 'headerInputs', 'headerInputsReadonly', 'inputSuffix' ],
  emits: ['cellEdited'],
  watch: {
    headerInputs: {
      handler() {
        this.attachHeaderInputs();
      },
      deep: true,
      immediate: true,
    },
    attachInputToHeaders: {
      handler() {
        this.attachHeaderInputs();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.attachHeaderInputs();
  },
  methods: {
    attachHeaderInputs() {
      if (!this.attachInputToHeaders || this.attachInputToHeaders.length === 0) {
        return;
      }
      this.$nextTick(() => {
        const table = this.$el.querySelector('.ag-theme-alpine');
        if (!table) return;

        const headers = table.querySelectorAll('.ag-header-cell');

        headers.forEach((header) => {
          const labelEl = header.querySelector('.ag-header-cell-label');
          if (!labelEl) {
            return;
          }
          const existingContainer = header.querySelector('.header-input-container');
          if (existingContainer) {
            existingContainer.remove();
          }

          const columnName = labelEl.textContent.trim();
          if (!this.attachInputToHeaders.includes(columnName)) {
            return;
          }
          const prefillValue = this.headerInputs ? this.headerInputs[columnName] : '';
          const inputContainer = document.createElement('div');
          inputContainer.className = 'header-input-container';

          const input = document.createElement('input');
          input.type = 'number';
          input.className = 'header-input';
          input.value = prefillValue;

          if (this.headerInputsReadonly) {
            input.setAttribute('readonly', true);
            input.style.backgroundColor = '#f5f5f5';
            input.style.cursor = 'not-allowed';
          }

          input.addEventListener('click', (e) => e.stopPropagation());
          input.addEventListener('input', (e) => {
            const columnName = header.textContent.trim().split('\n')[0];
            this.onInputChange?.(columnName, e.target.value);
          });

          const suffix = document.createElement('span');
          suffix.className = 'input-suffix';
          suffix.textContent = this.inputSuffix;

          inputContainer.appendChild(input);
          inputContainer.appendChild(suffix);
          labelEl.appendChild(inputContainer);
        });
      });
    },
    onCellEditRequest(event) {
      this.$emit('cellEdited', event);
    }
  }
};
</script>

<style scoped>
:deep(.ag-theme-alpine .ag-header-cell-label) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
}

:deep(.header-input-container) {
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
}

:deep(.header-input) {
  width: 45px;
  font-size: 14px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

</style>
