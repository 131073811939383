<template>
  <span
    v-if="!isObject"
    class="d-block me-5 pe-1"
  >
    {{ colValue }}
  </span>
  <div
    v-else
    class="revenue-cell-container"
  >
    <div class="revenue-label">
      <span class="forecast-data__container">
        {{ colValue.forecastAvg }}
      </span>
      <span class="actual-data__container">
        {{ colValue.actualAvg }}
      </span>
    </div>
    <div class="input-wrapper">
      <input
        type="text"
        class="forecast-row-input"
        :value="inputValue"
        :readonly="inputsReadOnly"
        @change="onInputChange"
      />
      <span class="percent-label">%</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['params'],
  data() {
    return {
      inputValue: this.params?.data?.inputValue || ''
    };
  },
  computed: {
    colValue() {
      const { field } = this.params.colDef;
      const rowData = this.params.data;
      return rowData[field];
    },
    isObject() {
      return typeof this.colValue === 'object';
    },
    textAlignClass() {
      return this?.params?.textAlign || 'right';
    },
    inputsReadOnly() {
      return this.params?.context?.inputsReadOnly || false;
    }
  },
  watch: {
    'params.data.inputValue': {
      immediate: true,
      handler(val) {
        this.inputValue = val;
      }
    }
  },
  mounted() {
    this.prefillInputs();
  },
  methods: {
    prefillInputs() {
      const revenueTypeName = this.params?.data?.revenueType?.forecastAvg;
      const context = this.params?.context;
      if (context?.prefillInputs && revenueTypeName) {
        this.inputValue = context.prefillInputs[revenueTypeName] ?? '';
      }
    },
    onInputChange(event) {
      const revenueTypeName = this.params?.data?.revenueType?.forecastAvg;
      const newValue = parseInt(event.target.value);
      this.inputValue = isNaN(newValue) ? '' : newValue;
      if (this.params.context?.onRevenueInputChange) {
        this.params.context.onRevenueInputChange(revenueTypeName, this.inputValue);
      }
    }
  }
};
</script>
<style scoped>
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.forecast-data__container {
  margin-top: 2px;
  font-size: 16px !important;
  line-height: 18px !important;
}
.actual-data__container {
  color: #918b8b;
  font-size: 14px !important;
  line-height: 15px !important;
}

.revenue-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
  box-sizing: border-box;
  width: 210px;
}

.revenue-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  text-align: left;
  flex-grow: 1;

}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.percent-label {
  font-size: 13px;
  font-weight: 500;
  color: #333;
}

.forecast-row-input {
  width: 30px;
  height: 24px;
  font-size: 13px;
  padding: 2px 2px;
  border: 1px solid #6e6b6b;
  background-color: rgba(85, 185, 255, 1);
  border-radius: 5px;
  text-align: center;
}

</style>
